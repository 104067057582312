import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import remarkDirective from "remark-directive"
import remarkDirectiveRehype from "remark-directive-rehype"
import "../styles/customMarkdown.scss"


const CustomMarkdown = ({ children, className, skipHtml }) => {

    const YouTubeVideo = ({ id, children }) => (
        <iframe
            src={'https://www.youtube.com/embed/' + id}
            width="560"
            height="315"
        >
            {children}
        </iframe>
    )

    const CustomLink = ({ children, title, rel, target }) => (

        <a href={children} target={target} rel={rel}>
            {title}
        </a>
    )

    const CTA = ({ children, title, rel, target }) => (
        <div className="customMarkdown_cta">
            <a href={children} className={'customMarkdown_cta-link ' + className} rel={rel}>{title}</a>
        </div>
    )

    const ReadMoreTruncate = ({ children }) => (
        <p>{children}...<span>find out more</span></p>
    )

    const CustomParagraph = ({ footerDisclaimer, children, disclaimer, position }) => {

        return <div className="custom-paragraph-container">
            {/* {React.createElement(element, {className: position}, children)} */}

            <p className={position}>{footerDisclaimer ? <sup>{footerDisclaimer}</sup> : ""} {children} {disclaimer ? <sup>{disclaimer}</sup> : ""}</p>

        </div>


    }

    return (
        <>
            <ReactMarkdown
                children={children}
                remarkPlugins={[remarkDirective, remarkDirectiveRehype]}
                rehypePlugins={[rehypeRaw]}
                components={{
                    'youtube-video': YouTubeVideo,
                    'custom-link': CustomLink,
                    'read-more-truncate': ReadMoreTruncate,
                    'cta': CTA,
                    'custom-paragraph': CustomParagraph,
                }}
                className={className}
                skipHtml={skipHtml}


            />
        </>
    )
}

export default CustomMarkdown