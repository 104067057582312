import React, { useEffect, useRef, useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import "./nav.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaPhone } from "react-icons/fa"
import CustomMarkdown from "../../utils/customMarkdown"
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'

const Nav = ({ homepageNav }) => {

    const data = useStaticQuery(graphql`
        {
            strapiSiteSettings{
                Logo{
                    url
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                width: 180
                                placeholder: NONE
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
                FoodURL
                DrinksURL
            }

            strapiNavMenu {
                LeftNav {
                  Title
                  URL
                }
                RightNav {
                  Title
                  URL
                }
                CTALabel
                CTAUrl
            }

            allStrapiMenuTypes(sort: {order: ASC, fields: created_at}) {
                edges {
                  node {
                    Title
                    Slug
                  }
                }
            }
        }
    
    `)



    const menu = useRef(null);
    const mobileMenu = useRef(null)


    // const [ctaBarVisible, setCtaBarVisibility] = useState(false)
    // function showHideCta() {
    //     var y = window.scrollY;
    //     if (y >= 400) {
    //         setCtaBarVisibility(true)
    //     } else {
    //         setCtaBarVisibility(false)
    //     }
    // }

    // useEffect(() => {
    //     window.addEventListener("scroll", showHideCta)
    // }, [])


    const [openMenu, setOpenMenu] = useState(false)
    const [openMobileMenu, setOpenMobileMenu] = useState(false)

    const closeMenu = (e) => {
        if (menu.current && openMenu && !menu.current.contains(e.target)) {
            setTimeout(() => {
                setOpenMenu(false)
            }, 90)
        }
    }
    const closeMobileMenu = (e) => {
        if (mobileMenu.current && openMobileMenu && !mobileMenu.current.contains(e.target)) {
            setTimeout(() => {
                setOpenMobileMenu(false)
            }, 90)
        }
    }

    const [showElement, setShowElement] = useState(false)
    useEffect(() => {
        setTimeout(function () {
            setShowElement(true)
        }, 300);
    },
        [])


    const [showNavbar, setShowNavbar] = useState(false);

    function scrollNavbar() {

        if (homepageNav == true) {

            var y = window.scrollY;

            if (y >= 300) {

                setShowNavbar(true)
            } else {
                setShowNavbar(false)
            }
        } else {

            setShowNavbar(true);
        }

    }




    useEffect(() => {

        if (typeof window !== 'undefined') {

            document.addEventListener('mousedown', closeMenu)
            document.addEventListener('mousedown', closeMobileMenu)

            return () => {
                window.removeEventListener('mousedown', closeMenu);
                window.removeEventListener('mousedown', closeMobileMenu);
            };
        }

    }, [openMenu, openMobileMenu])

    useEffect(() => {

        if (typeof window !== 'undefined') {
            window.addEventListener("scroll", scrollNavbar)
        }

    }, [])


    if (typeof window !== 'undefined') {
        if (openMobileMenu && !homepageNav) {
            document.body.setAttribute('style', `position: fixed; top: 0; left: 0; right: 0;`)
        } else {
            document.body.setAttribute('style', ``)
        }
    }

    const handleGtagNavPhone = () => {
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', 'nav-phone');

        }
    }

    const handleGtagNavBookNow = () => {
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', 'nav-book-now');

        }
    }

    const handleGtagMobileMenuBookNow = () => {
        if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {

            window.gtag('event', 'mobile-menu-book-now');

        }
    }

    return (
        <div>

            {
                //for homepage it would need showNavBar, for the rest it will go from the !homepageNav
                (showNavbar || !homepageNav) &&

                <div className={"nav-theme navigationBar"}>
                    <div data-uk-sticky=" sel-target: .uk-navbar-container; top:1;" className={openMobileMenu ? "uk-sticky uk-sticky-fixed uk-sticky-below" : ""}>
                        {/* nav bar with the logo */}
                        <nav className="nav-options uk-navbar-container uk-animation-slide-top-small" data-uk-navbar>

                            {/* left navbar */}
                            <div className="uk-navbar-left uk-visible@m">
                                <ul className="uk-navbar-nav menu-items-nav">
                                    {data.strapiNavMenu ? data.strapiNavMenu.LeftNav.map((item, index) => {
                                        return (
                                            <li key={index} className={`menu-item-visible-index-` + index}>
                                                <a href={item.URL} rel="nofollow">{item.Title}</a>
                                                {/*desktop - subcontainer for 'menu' option*/}
                                                {
                                                    (item.Title.includes("Menu") || item.Title.includes("menu") || item.Title.includes("MENU")) ?
                                                        <div className="uk-navbar-dropdown submenu-container" data-uk-dropdown="offset: 3">
                                                            <div className="submenu-container__display-flex">
                                                                <div className="submenu-content">
                                                                    {
                                                                        data.allStrapiMenuTypes.edges.map((itemMenu, indexMenu) => {
                                                                            return (
                                                                                <a key={indexMenu} href={itemMenu.node.Title == "Food" ? data.strapiSiteSettings.FoodURL : itemMenu.node.Title == "Drinks" ? data.strapiSiteSettings.DrinksURL : itemMenu.node.Title == "Valentine" ? itemMenu.node.Slug : data.strapiSiteSettings.DessertURL} rel="nofollow">{itemMenu.node.Title} Menu</a>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ""
                                                }
                                            </li>
                                        )

                                    }) : ""}
                                </ul>
                            </div>

                            {/* logo */}
                            <div className="uk-navbar-left nav-options-left" >
                                <ul className="uk-navbar-nav">
                                    <li key={Math.random()}>
                                        <a className="logo" href="/" rel="nofollow" aria-label="light-logo">
                                            {data.strapiSiteSettings.Logo.localFile.childImageSharp ?
                                                <GatsbyImage image={data.strapiSiteSettings.Logo.localFile.childImageSharp.gatsbyImageData} loading="eager" alt="La Tagliata logo" width="180" height="34"></GatsbyImage>
                                                : <img src={data.strapiSiteSettings.Logo.url} alt="La Tagliata logo" width="180" height="34" />
                                            }

                                        </a>
                                    </li>
                                </ul>
                            </div>

                            {/* right navbar */}
                            <div className="uk-navbar-left uk-visible@m">
                                <ul className="uk-navbar-nav menu-items-nav">
                                    {data.strapiNavMenu ? data.strapiNavMenu.RightNav.map((item, index) => {
                                        return (
                                            <li key={index} className={`menu-item-visible-index-` + index}>
                                                <a href={item.URL} rel="nofollow">{item.Title}</a>
                                                {/*desktop - subcontainer for 'menu' option*/}
                                                {
                                                    (item.Title.includes("Menu") || item.Title.includes("menu") || item.Title.includes("MENU")) ?
                                                        <div className="uk-navbar-dropdown submenu-container" data-uk-dropdown="offset: 3">
                                                            <div className="submenu-container__display-flex">
                                                                <div className="submenu-content">
                                                                    {
                                                                        data.allStrapiMenuTypes.edges.map((itemMenu, index) => {
                                                                            
                                                                            return (
                                                                                <a key={index} href={itemMenu.node.Title == "Food" ? data.strapiSiteSettings.FoodURL : itemMenu.node.Title == "Drinks" ? data.strapiSiteSettings.DrinksURL : itemMenu.node.Title == "Valentine" ? itemMenu.node.Slug : data.strapiSiteSettings.DessertURL} rel="nofollow">{itemMenu.node.Title} Menu</a>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ""
                                                }
                                            </li>
                                        )

                                    }) : ""}
                                </ul>
                            </div>

                            {/* right navbar menu toggle button hidden on desktop but shown on mobile - align-right*/}
                            {showElement ?
                                <div className="uk-navbar-right uk-animation-fade" ref={mobileMenu}>

                                    {
                                        data.strapiNavMenu.CTAUrl ?
                                            <div onClick={() => handleGtagNavBookNow()}>
                                                <a href={data.strapiNavMenu.CTAUrl} className="nav-cta uk-hidden@m" target="_blank">{data.strapiNavMenu.CTALabel}</a>
                                            </div>
                                            : ""
                                    }

                                    <div onClick={() => handleGtagNavPhone()}>
                                        <a href="tel:02073771551" className="phone-icon" aria-label="laTagliata-phoneNumber"><FaPhone /></a>
                                    </div>

                                    {
                                        openMobileMenu ?
                                            <a href="#offcanvas-usage" className="uk-navbar-toggle menuMobileBtn uk-hidden@m" data-uk-toggle="target: #offcanvas-menu" onClick={() => setOpenMobileMenu(false)}>
                                                <span className="uk-margin-small-right">Close <AiOutlineClose size={'1.1rem'} /></span>
                                            </a>
                                            :
                                            <a href="#offcanvas-usage" className="uk-navbar-toggle menuMobileBtn uk-hidden@m open-menu" data-uk-toggle="target: #offcanvas-menu" onClick={() => setOpenMobileMenu(true)}>
                                                <span className="uk-margin-small-right" >Menu<AiOutlineMenu size={'1.1rem'} /></span>
                                            </a>
                                    }

                                </div>
                                : ""
                            }


                            {/* mobile side menu only visible on mobile */}
                            <div id="offcanvas-menu" className="off-canv uk-offcanvas" data-uk-offcanvas="overlay: false; flip: false; mode: none" style={openMobileMenu ? { display: 'block' } : { display: 'none' }}>
                                <div className="uk-offcanvas-bar uk-flex uk-flex-column offcanvas-container canvas-container-mobile uk-hidden@m uk-animation-slide-right-small">

                                    <ul className="uk-nav uk-nav-primary uk-nav-left offcanvas-menu-list reposition">

                                        {
                                            data.strapiNavMenu.CTAUrl ?
                                                <div onClick={() => handleGtagMobileMenuBookNow()}>
                                                    <a href={data.strapiNavMenu.CTAUrl} className="nav-cta-side uk-hidden@m" target="_blank">{data.strapiNavMenu.CTALabel}</a>
                                                </div>

                                                : ""
                                        }

                                        {/* left */}
                                        {data.strapiNavMenu ? data.strapiNavMenu.LeftNav.map((item, index) => {
                                            return (

                                                <li key={index} className={`offcanvas-menu-item-visible-index-` + index}>
                                                    <a href={item.URL} rel="nofollow">{item.Title}</a>
                                                    {/*mobile - subcontainer for 'menu' option*/}
                                                    {
                                                        (item.Title.includes("Menu") || item.Title.includes("menu") || item.Title.includes("MENU")) ?
                                                            <ul className="submenu-types" style={{ paddingLeft: "32px", listStyle: "disc" }}>
                                                                {
                                                                    data.allStrapiMenuTypes.edges.map((itemMenu, indexMenu) => {
                                                                        return (
                                                                            <li key={indexMenu}><a href={itemMenu.node.Title == "Food" ? data.strapiSiteSettings.FoodURL : itemMenu.node.Title == "Drinks" ? data.strapiSiteSettings.DrinksURL : itemMenu.node.Title == "Valentine" ? itemMenu.node.Slug : data.strapiSiteSettings.DessertURL} rel="nofollow">{itemMenu.node.Title} Menu</a></li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                            : ""
                                                    }
                                                </li>
                                            )
                                        }) : ""
                                        }

                                        {/* right */}
                                        {data.strapiNavMenu ? data.strapiNavMenu.RightNav.map((item, index) => {
                                            return (

                                                <li key={index} className={`offcanvas-menu-item-visible-index-` + index}>
                                                    <a href={item.URL} rel="nofollow">{item.Title}</a>
                                                    {
                                                        (item.Title.includes("Menu") || item.Title.includes("menu") || item.Title.includes("MENU")) ?
                                                            <ul className="submenu-types" style={{ paddingLeft: "32px", listStyle: "disc" }}>
                                                                {
                                                                    data.allStrapiMenuTypes.edges.map((itemMenu, indexMenu) => {
                                                                        return (
                                                                            <li key={indexMenu}><a href={itemMenu.node.Title == "Food" ? data.strapiSiteSettings.FoodURL : itemMenu.node.Title == "Drinks" ? data.strapiSiteSettings.DrinksURL : itemMenu.node.Title == "Valentine" ? itemMenu.node.Slug : data.strapiSiteSettings.DessertURL} rel="nofollow">{itemMenu.node.Title} Menu</a></li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                            : ""
                                                    }
                                                </li>

                                            )
                                        }) : ""
                                        }


                                    </ul>

                                </div>

                            </div>

                        </nav>
                    </div>
                    <div className={openMobileMenu ? "display-content" : ""}></div>
                </div>
            }

        </div>
    )
}

export default Nav