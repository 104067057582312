import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import "../../styles/variables.scss"


function Seo({ description, lang, meta, title }) {
    const { site, strapiGlobal } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              title
              description
              author
            }
          }

          strapiGlobal {
            defaultSeo {
              metaDescription
              metaTitle
            }
            siteName
          }
        }
      `
    )


    const metaDescription = description || strapiGlobal.defaultSeo.metaDescription
    var metaTitle = title || strapiGlobal.defaultSeo.metaTitle


    if(title == "La Tagliata") {
      metaTitle = title + " | " + strapiGlobal.defaultSeo.metaDescription
    }


  
    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        link={[  
          {
            rel: "preconnect",
            href:
              "https://fonts.gstatic.com",
          },
          {
            rel: "preconnect",
            href:
              "https://cdn.jsdelivr.net",
          },
          {
            rel: "preconnect",
            href:
              "https://fonts.googleapis.com/",
          },
          {
            rel: "preload",
            href: "https://fonts.googleapis.com/css?family=Muli:regular,italic%7CMuli:300,regular",
            as: "style"
          },
          {
            rel: "stylesheet",
            href: "https://fonts.googleapis.com/css?family=Muli:regular,italic%7CMuli:300,regular",
           // async: true
          },
          // {
          //   rel: "stylesheet",
          //   href:
          //     "https://cdn.jsdelivr.net/npm/uikit@3.7.0/dist/css/uikit.min.css",
          // }
           
        ]}
        script={[  
          // {
          //   src:
          //     "https://cdn.jsdelivr.net/npm/uikit@3.7.0/dist/js/uikit.min.js",
          // },
          // {
          //   src:
          //     "https://cdn.jsdelivr.net/npm/uikit@3.7.0/dist/js/uikit-icons.min.js",
          // }
        ]}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
        //   {
        //     name: `twitter:card`,
        //     content: `summary`,
        //   },
        //   {
        //     name: `twitter:creator`,
        //     content: site.siteMetadata.author,
        //   },
        //   {
        //     name: `twitter:title`,
        //     content: metaTitle,
        //   },
        //   {
        //     name: `twitter:description`,
        //     content: metaDescription,
        //   },
        ].concat(meta)}
  
     >

       {/** List of meta properties coming from strapi settings that will apply globally across all pages */
       
        strapiGlobal.MetaTags?.map((item, index) => {
          return(
            <meta key={`meta-key-`+ index} name={item.MetaName} content={item.MetaContent} />
          )
        })
       
       }

      </Helmet>
    )
  }

  Seo.defaultProps = {
    lang: 'en',
    meta: [],
    description:'',
    title: ''
  }
  
  Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
  }
  
  export default Seo
  