import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { PiLinkedinLogoBold } from "react-icons/pi";
import { FaXTwitter } from "react-icons/fa6";
import "./footer.scss"
import CustomMarkdown from "../../utils/customMarkdown"
import rating from "../../assets/rating.png";

const Footer = () => {

    const data = useStaticQuery(graphql`
        {
            strapiFooter {
                Company
                Copyright
                Pages {
                  CTALabel
                  CTAUrl
                }
                Disclaimer
              }
        }
    `)

    return (
        <>
            <div className="footer-section">
                <div style={{ marginBottom: "20px" }}>
                    <a className="social-icon" href="https://www.facebook.com/profile.php?id=100093801671575" aria-label="facebook-social" target="_blank"><FaFacebookF /></a>
                    <a className="social-icon" href="https://www.instagram.com/latagliatalondon/" aria-label="instagram-social" target="_blank"><FaInstagram /></a>
                    <a className="social-icon" href="https://twitter.com/TagliataLondon" aria-label="x-social" target="_blank"><FaXTwitter /></a>
                    <a className="social-icon" href="https://uk.linkedin.com/company/la-tagliata-italian-kitchen" aria-label="linkedin-social" target="_blank"><PiLinkedinLogoBold /></a>
                </div>
                {
                    data.strapiFooter.Company ?
                        <CustomMarkdown children={data.strapiFooter.Company} className="company" />
                        : ""
                }
                {
                    data.strapiFooter.Copyright ?
                        <p className="copyright">{data.strapiFooter.Copyright + " " + new Date().getFullYear()}</p>
                        : ""
                }
                {
                    data.strapiFooter.Pages ?
                        <div>
                            {
                                data.strapiFooter.Pages?.map((item, index) => {
                                    return (
                                        <div className="pages-container" key={index}>
                                            <a rel="noopener noreferrer" href={item.CTAUrl} className="cta">{item.CTALabel}</a>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        : ""
                }

                <a href="https://ratings.food.gov.uk/business/49823/pizza-finchi-london/online-ratings" target="_blank" aria-label="hygiene-score">
                    <img src={rating} style={{ width: "120px" }} alt="hygiene-score"/>
                </a>
            </div>
        </>
    )
}

export default Footer